<template>
  <!-- 首页 -->
  <div class="index">
    <!-- 头部 -->
    <!-- <myHeader></myHeader> -->

    <!-- pc轮播图 -->
    <el-carousel
        height="100vh"
        :interval="5000"
        :arrow="hover"
        indicator-position="outside"
        class="pc"
    >
      <el-carousel-item v-for="item in banner" :key="item">
        <img :src="item.carouselPhoto" alt="" width="100%"/>
      </el-carousel-item>
    </el-carousel>
    <!-- 移动端轮播图 -->
    <el-carousel
        height="212px"
        :interval="5000"
        :arrow="hover"
        class="ydd"
        indicator-position="outside"
    >
      <el-carousel-item v-for="item in banner" :key="item">
        <img :src="item.carouselPhoto" alt="" width="100%"/>
      </el-carousel-item>
    </el-carousel>
    <!---企业动态-->
    <div class="djyl">
      <img class="title_img" src="../../assets/img/qydt.png" alt=""/>
      <div class="banner_box2">
        <div
            class="content"
            v-for="item in dyncList"
            :key="item"
            @click="toInfo(item)"
        >
          <img :src="item.photo3" alt=""/>
          <div class="box1">
            <h4>{{ item.menuName }}</h4>
            <p v-html="item.bookContent">
              <!-- {{ item.bookContent }} -->
            </p>
            <a>了解更多>></a>
          </div>
        </div>
      </div>
    </div>
    <!---党建引领-->
    <div class="djyl">
      <img class="title_img" src="../../assets/img/djyl.png" alt=""/>
      <div class="banner_box2">
        <div
            class="content"
            v-for="item in partyTuiList"
            :key="item"
            @click="toInfo(item)"
        >
          <img :src="item.photo3" alt=""/>
          <div class="box1">
            <h4>{{ item.menuName }}</h4>
            <p v-html="item.bookContent">
              <!-- {{ item.bookContent }} -->
            </p>
            <a>了解更多>></a>
          </div>
        </div>
      </div>
    </div>
    <!-- 门店风采 -->
    <div class="mdfc">
      <img class="title_img" src="../../assets/img/门店风采标题.png" alt=""/>
      <div class="banner_box2">
        <div class="left">
          <div
              class="box t1"
              v-for="item in getlist2MentuOne"
              :key="item"
              @click="toInfo(item)"
          >
            <img :src="item.photo3" alt=""/>
            <h4>{{ item.menuName }}</h4>
            <p v-html="item.bookContent">
              <!-- {{ item.bookContent }} -->
            </p>
            <a>了解更多>></a>
          </div>
        </div>
        <div class="right">
          <div class="news" v-for="item in getlist2MentuTwo" :key="item">
            <h3 @click="toInfo(item)">{{ item.menuName }}</h3>
            <p @click="toInfo(item)" v-html="item.bookContent">
              <!-- {{ item.bookContent }} -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <!---时尚阅读-->
    <div class="ssyd">
      <img class="title_img" src="../../assets/img/时尚阅读标题.png" alt=""/>
      <!-- 轮播图 -->
      <div class="main">
        <el-carousel indicator-position="outside" height="500px" arrow="never">
          <el-carousel-item>
            <div class="show">
              <ul class="bookShow">
                <li v-for="item in bookList" :key="item">
                  <img
                      :src="item.bookPhoto"
                      alt=""
                      @click="toIndexTwo(item.id)"
                      style="width:270px height:300px"
                  />
                  <p>{{ item.bookTitle }}</p>
                </li>
              </ul>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图移动端 -->
      <swiper
          :slides-per-view="3"
          :space-between="50"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide v-for="item in bookList" :key="item"
        ><img
            :src="item.bookPhoto"
            alt=""
            @click="toIndexTwo(item.id)"
            style="width:100%;height:550px;padding:10px"
        />
        </swiper-slide>
      </swiper>
    </div>
    <!-- 企业简介-->
    <div class="qyjj">
      <img class="title_img" src="../../assets/img/企业文化.png" alt=""/>
      <div class="content">
        <div class="box">
          <h2>企业文化精神</h2>
          <h2>爱党、爱国、爱店、敬业</h2>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <!-- <myFooter></myFooter> -->
  </div>
</template>
<script>
// import myHeader from '../myHeader'
// import myFooter from '../myFooter'
import {
  listBookwm,
  partyTui,
  partyTuiOne,
  partyTuiTwo,
  listTbCarousel
} from '@/api/myHeader.js'

export default {
  components: {},
  created() {
    listTbCarousel().then(res => {
      this.banner = res.data.rows
      console.log('轮播图', res.data.rows);
    })
    listBookwm(this.queryParams).then(res => {
      this.bookList = res.data.rows
    })
    partyTui(2129).then(res => {
      //alert('12')
      this.partyTuiList = res.data.data
    })
    partyTui(2137).then(res => {
      //alert('12')
      this.dyncList = res.data.data
    })
    partyTuiOne(2130).then(res => {
      //alert('12')
      this.getlist2MentuOne = res.data.data
    })
    partyTuiTwo(2130).then(res => {
      //alert('12')
      this.getlist2MentuTwo = res.data.data
    })
  },

  data() {
    return {
      partyTuiList: [],
      dyncList: [],
      activeIndex: '1',
      activeIndex2: '1',
      banner: [
        // require('../../assets/img/banner01.jpg'),
        // require('../../assets/img/banner02.jpg?w=1000'),
        // require('../../assets/img/banner03.jpg?w=1000'),
        // require('../../assets/img/banner04.jpg?w=1000'),
        // require('../../assets/img/banner05.jpg?w=1000'),
        // require('../../assets/img/banner06.jpg?w=1000'),
        // require('../../assets/img/banner07.jpg?w=1000'),
        // // require(''),
      ],
      bookList: [],
      getlist2MentuOne: [],
      getlist2MentuTwo: [],
      list: [
        {
          url1: require('../../assets/img/背包十年.png'),
          font1: '《背包十年》'
        }
      ],
      queryParams: {
        pageNum: 0,
        pageSize: 4
      }
    }
  },
  mounted() {
  },
  methods: {
    substrByLen(str, leng) {
      if (str.length > leng) {
        var i = leng
        while (str.length > leng) {
          i--
          str = str.substring(0, i)
        }

        str = str + '...'
      }
      return str
    },
    toInfo(item) {
      console.log('11', item)
      if (item.isFrame == 1) {
        console.log('====', item.articleId)
        let {href} = this.$router.resolve({
          path: '/Info', // 这里写的是要跳转的路由地址
          query: {id: item.articleId} // 这里写的是页面参数
        })
        window.open(href, '_blank')
      } else {
        this.$router.push(
            'https://fanyi.baidu.com/?aldtype=16047#en/zh/Avoided%20redundant%20navigation%20to%20current%20location%3A%20%22%2Fparty%22.'
        )
      }
    },
    toIndexTwo(id) {
      let {href} = this.$router.resolve({
        path: '/dsInfo', // 这里写的是要跳转的路由地址
        query: {id: id} // 这里写的是页面参数
      })
      window.open(href, '_blank')
    },
    getList() {
      listBookwm(this.queryParams).then(res => {
        this.bookList = res.data.rows
      })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    toBookInfo() {
      this.$router.push({
        push: '/bookInfo',
        name: 'bookInfo'
      })
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    }
  }
}
</script>
<style lang="less">
// pc端
@media only screen and (min-width: 768px) {
  .index {
    z-index: -3;
    // width: 100%;
    // height: 80px;
    //   background-color: #f60;
    em {
      font-weight: normal;
      font-style: normal;
    }

    .ydd {
      display: none;
    }

    // 轮播图
    .el-carousel__item h3 {
      // color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__button {
      background-color: #cc1914;
    }

    // 党建引领
    .djyl {
      width: 100%;
      text-align: center;
      // margin-bottom: 100px;
      overflow: hidden;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 52px auto;
      }

      .banner_box2 {
        width: 1200px;
        height: 475px;
        margin: 0 auto;
        // background-color: #00f;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .content {
          width: 350px;
          height: 420px;
          box-shadow: 0px 0px 10px #999;
          cursor: pointer;

          // background-color: #f60;
          img {
            display: block;
            width: 350px;
            height: 262px;
            // width: 100%;
          }

          .box1 {
            padding: 20px;

            h4 {
              margin: 0 0 20px;
              font-size: 14px;
              color: #333;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              height: 20px;
              line-height: 20px;
            }

            p {
              height: 40px;
              line-height: 22px;
              color: #666;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            a {
              display: block;
              width: 125px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: #fff;
              background-color: #cc1914;
              margin: 20px auto;
              cursor: pointer;
            }
          }
        }

        .content:hover {
          // width: 430px;
          // height: 535px;
          transition: all 0.6s;
          transform: scale(1.1);
        }
      }
    }

    /*门店风采*/

    .mdfc {
      width: 100%;
      text-align: center;
      // margin-bottom: 100px;
      overflow: hidden;
      margin-bottom: 30px;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 52px auto;
      }

      .banner_box2 {
        display: flex;
        width: 1200px;
        // height: 360px;
        margin: 0 auto;
        text-align-last: left;
        // background-color: #00f;
        .left {
          display: flex;
          // float: left;
          height: 300px;
          width: 510px;

          // background-color: #f60;
          .box {
            width: 230px;
            height: 100%;
            padding: 10px;
            cursor: pointer;

            img {
              width: 100%;
            }

            h4 {
              font-size: 14px;
              color: #333;
              margin: 8px 0 8px;
              height: 20px;
              line-height: 20px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            p {
              height: 60px;
              color: #666;
              line-height: 20px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

              span {
                color: #666 !important;
              }
            }

            a {
              display: block;
              width: 125px;
              height: 30px;
              line-height: 30px;
              color: #fff;
              background-color: #cc1914;
              margin-top: 15px;
              padding-left: 30px;
              cursor: pointer;
            }
          }

          .t1 {
            // float: left;
            // background-color: #f00;
            margin-right: 50px;
          }

          .t2 {
            float: right;
            // background-color: #00f;
          }
        }

        .right {
          // display: flex;
          // float: right;
          width: 600px;
          height: 290px;
          // background-color: #f60;
          .news {
            height: 80px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;
            cursor: pointer;

            h3 {
              font-size: 16px;
              margin-bottom: 10px;
              color: #333;
            }

            p {
              height: 44px;
              font-size: 12px;
              line-height: 22px;
              color: #666;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }

    /*时尚阅读*/

    .ssyd {
      width: 100%;
      height: 580px;
      text-align: center;
      background-color: #f5f5f5;
      overflow: hidden;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 52px auto;
      }

      .main {
        .el-carousel__button {
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }

        width: 1200px;
        margin: 0 auto;
        // background-color: #cc1914;
        .bookShow {
          position: relative;
          width: 1200px;
          height: 100%;
          margin: 0 auto;
          // background-color: #f60;
          display: flex;
          justify-content: space-between;
          text-align: center;
          margin-bottom: 30px;

          li {
            width: 270px;
            height: 300px;
            // background-color: #00f;
            img {
              display: block;
              width: 270px;
              height: 330px;
              // width: 100% !important;
              // height: 100%  !important;
              cursor: pointer;
              // margin-bottom: 25px;
            }

            p {
              font-size: 16px;
              margin: 10px;
            }
          }
        }

        // img {
        //     width: 80%;
        // }

        // .prev {
        //     position: absolute;
        //     left: 44%;
        //     bottom: -20%;
        //     width: 140px;
        //     height: 30px;
        //     background-color: #f5f5f5;

        //     // background-color: #f60;
        // }
      }

      .el-carousel--horizontal {
        margin-top: 0px;
      }
    }

    /*企业简介*/

    .qyjj {
      width: 100%;
      text-align: center;
      // margin-bottom: 60px;
      overflow: hidden;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 52px auto;
      }

      .content {
        position: relative;
        background: url('../../assets/img/qyjj.png') no-repeat;
        background-size: 100%;
        width: 100%;
        height: 385px;
        margin-bottom: 40px;

        .box {
          position: absolute;
          text-align: left;
          top: 20%;
          left: 55%;
          // width: 710px;
          // height: 210px;
          // line-height: 210px;
          color: #fff;
          text-align: center;

          h2 {
            font-size: 39px;
            margin-bottom: 75px;
            margin-top: 37px;
          }

          // background-color: #f60;
          h3 {
            font-size: 24px;
            text-align: left;
            margin-bottom: 35px;
          }

          p {
            font-size: 14px;
            line-height: 24px;

            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

// 移动端
@media only screen and (max-width: 768px) {
  .index {
    width: 100%;
    z-index: -100;

    em {
      font-weight: normal;
      font-style: normal;
    }

    .pc {
      display: none;
    }

    // 轮播图
    .el-carousel__item h3 {
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__indicators--horizontal {
      bottom: 0;
      left: 50%;
    }

    .el-carousel__container {
      height: 180px !important;
    }

    .el-carousel__button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #cc1914;
    }

    // 党建引领
    .djyl {
      width: 100%;
      text-align: center;
      overflow: hidden;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 20px auto;
      }

      .banner_box2 {
        width: 100%;
        margin: 0 auto;
        // display: flex;
        // justify-content: space-around;
        // align-items: center;

        .content {
          width: 100%;
          // box-shadow: 0px 0px 10px #999;
          cursor: pointer;

          img {
            display: block;
            width: 100%;
            padding: 0 20px;
          }

          .box1 {
            padding: 20px;

            h4 {
              margin: 0 0 20px;
              font-size: 14px;
              color: #333;
              height: 20px;
              line-height: 20px;
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-line-clamp: 1;
              // -webkit-box-orient: vertical;
            }

            p {
              height: 40px;
              line-height: 22px;
              color: #666;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            a {
              display: block;
              width: 125px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              color: #fff;
              background-color: #cc1914;
              margin: 20px auto;
              cursor: pointer;
            }
          }
        }

        // .content:hover {
        //   transition: all 0.6s;
        //   transform: scale(1.1);
        // }
      }
    }

    /*门店风采*/

    .mdfc {
      width: 100%;
      text-align: center;
      overflow: hidden;
      margin-bottom: 30px;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 20px auto;
      }

      .banner_box2 {
        width: 100%;
        margin: 0 auto;
        text-align-last: left;

        .left {
          width: 100%;

          .box {
            width: 100%;
            height: 100%;
            padding: 20px;
            cursor: pointer;
            text-align: center;

            img {
              width: 100%;
            }

            h4 {
              font-size: 14px;
              color: #333;
              margin: 8px auto 8px;
              height: 18px;
              line-height: 18px;
            }

            p {
              height: 40px;
              color: #666;
              line-height: 20px;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              span {
                color: #666 !important;
              }
            }

            a {
              display: block;
              width: 125px;
              height: 30px;
              line-height: 30px;
              color: #fff;
              background-color: #cc1914;
              margin-top: 15px;
              padding-left: 30px;
              cursor: pointer;
              margin: 20px auto;
            }
          }

          .t1 {
            margin-right: 50px;
          }

          .t2 {
            float: right;
          }
        }

        .right {
          width: 100%;
          // height: 290px;
          padding: 0 10px;

          .news {
            // height: 0px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;
            cursor: pointer;

            h3 {
              font-size: 16px;
              margin-bottom: 10px;
              color: #333;
              word-break: break-all;
              text-overflow: ellipsis;
              line-height: 22px;
              // overflow: hidden;
              // display: -webkit-box;
              // -webkit-line-clamp: 1;
              // -webkit-box-orient: vertical;
            }

            p {
              height: 44px;
              font-size: 12px;
              line-height: 22px;
              color: #666;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              padding-bottom: 10px;
            }
          }
        }
      }
    }

    /*时尚阅读*/

    .ssyd {
      width: 100%;
      text-align: center;

      .main {
        display: none;
      }

      .swiper-slide {
        background-color: #f3f3f3;
      }

      img {
        padding: 20px !important;
      }
    }

    /*企业简介*/

    .qyjj {
      width: 100%;
      text-align: center;
      // margin-bottom: 60px;
      overflow: hidden;

      .title_img {
        width: 285px;
        height: 73px;
        margin: 20px auto;
      }

      .content {
        position: relative;
        background: url('../../assets/img/qyjj.png') no-repeat;
        background-size: 100%;
        width: 100%;
        height: 85px;
        margin-bottom: 10px;

        .box {
          position: absolute;
          text-align: left;
          top: 7%;
          left: 48%;
          // width: 710px;
          // height: 210px;
          // line-height: 210px;
          color: #fff;
          text-align: center;

          h2 {
            font-size: 0.18rem;
            // margin-bottom: 75px;
            margin-top: 0.38rem;
          }

          // background-color: #f60;
          h3 {
            font-size: 0.24rem;
            text-align: left;
            margin-bottom: 0.35rem;
          }

          p {
            font-size: 0.14rem;
            line-height: 0.24rem;

            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  // height: 200px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
